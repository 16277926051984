<template>
  <v-stepper v-model="actionParams.wizardStep">
    <v-stepper-header>
      <v-stepper-step :complete="actionParams.wizardStep > 1" step="1">
        {{ $t("STEP") }} 1
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 2" step="2">
        {{ $t("STEP") }} 2
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 3" step="3">
        {{ $t("STEP") }} 3
      </v-stepper-step>
      <v-divider></v-divider>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <StepStockAuditAddSelectStore
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :globalForDate.sync="globalForDate"
          :items.sync="items"
          :selectedStore.sync="selectedStore"
        >
        </StepStockAuditAddSelectStore>
      </v-stepper-content>
      <v-stepper-content step="2">
        <StepStockAuditSetItems
          :firstLoader.sync="tempFirstLoader"
          :wizardStep.sync="actionParams.wizardStep"
          :globalForDate.sync="globalForDate"
          :notes.sync="notes"
          :items.sync="items"
          :selectedStore="selectedStore"
        >
        </StepStockAuditSetItems>
      </v-stepper-content>
      <v-stepper-content step="3">
        <StepStockAuditPreview
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :globalForDate.sync="globalForDate"
          :notes.sync="notes"
          :items.sync="items"
          :selectedStore="selectedStore"
          @on-save="onSave"
        >
        </StepStockAuditPreview>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import StepStockAuditAddSelectStore from "@/modules/stock/components/audits/StepStockAuditAddSelectStore.vue";
import StepStockAuditSetItems from "@/modules/stock/components/audits/StepStockAuditSetItems.vue";
import StepStockAuditPreview from "@/modules/stock/components/audits/StepStockAuditPreview.vue";

import { SAVE_STOCK_AUDIT } from "@/modules/stock/store/stock.module";

import _ from "lodash";
import moment from "moment";
export default {
  name: "StockAuditAddStepper",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      doGetItems: true,
      actionParams: {
        wizardStep: 1
      },
      globalForDate: moment().format("YYYY-MM-DD"),
      notes: "",
      items: [],
      selectedStore: {}
    };
  },
  components: {
    StepStockAuditAddSelectStore,
    StepStockAuditSetItems,
    StepStockAuditPreview
  },
  mounted() {},
  computed: {
    tempFirstLoader: {
      get: function () {
        return this.firstLoader;
      },
      set: function (newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    }
  },
  watch: {},
  methods: {
    onSave(payload) {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function () {
          vm.$store
            .dispatch(SAVE_STOCK_AUDIT, payload)
            .then((data) => {
              vm.errors = data.data.errors;
              // alert(data.data.errors);
              // let itemEdit = data.data;
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              vm.doGetItems = true;
              vm.updateWizardStep(1);
            })
            .catch(function (response) {
              vm.errors = response.data.errors;
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.data.message
              });
            });
        })
        .catch(function () {});
    },
    updateWizardStep(step) {
      let vm = this;
      vm.actionParams.wizardStep = step;
    }
  }
};
</script>
